module WinJS.UI {

    class _Adaptive {

        private processed = false;

        // media query sizes 
        private xsmall = "(max-width:767px)";
        private small = "(min-width:768px) and (max-width:991px)";
        private medium = "(min-width:992px) and (max-width:1199px)";
        private large = "(min-width:1200px)";


        private _element: HTMLElement
        public get element(): HTMLElement {
            return this._element;
        }


        private _onViewXSmall: EventListenerOrEventListenerObject
        public get onViewXSmall(): EventListenerOrEventListenerObject {
            return this._onViewXSmall;
        }
        public set onViewXSmall(eventHandler: EventListenerOrEventListenerObject) {
            if (this._onViewXSmall) {
                this.removeEventListener("viewXSmall", this._onViewXSmall);
                this._onViewXSmall = null;
            }

            this._onViewXSmall = eventHandler;
            this.addEventListener("viewXSmall", this._onViewXSmall);
        }

        private _onViewSmall: EventListenerOrEventListenerObject
        public get onViewSmall(): EventListenerOrEventListenerObject {
            return this._onViewSmall;
        }
        public set onViewSmall(eventHandler: EventListenerOrEventListenerObject) {
            if (this._onViewSmall) {
                this.removeEventListener("viewSmall", this._onViewSmall);
                this._onViewSmall = null;
            }

            this._onViewSmall = eventHandler;
            this.addEventListener("viewSmall", this._onViewSmall);
        }

        private _onViewMedium: EventListenerOrEventListenerObject
        public get onViewMedium(): EventListenerOrEventListenerObject {
            return this._onViewMedium;
        }
        public set onViewMedium(eventHandler: EventListenerOrEventListenerObject) {
            if (this._onViewMedium) {
                this.removeEventListener("viewMedium", this._onViewMedium);
                this._onViewMedium = null;
            }

            this._onViewMedium = eventHandler;
            this.addEventListener("viewMedium", this._onViewMedium);
        }

        private _onViewLarge: EventListenerOrEventListenerObject
        public get onViewLarge(): EventListenerOrEventListenerObject {
            return this._onViewLarge;
        }
        public set onViewLarge(eventHandler: EventListenerOrEventListenerObject) {
            if (this._onViewLarge) {
                this.removeEventListener("viewLarge", this._onViewLarge);
                this._onViewLarge = null;
            }

            this._onViewLarge = eventHandler;
            this.addEventListener("viewLarge", this._onViewLarge);
        }




        private _onViewChanged: EventListenerOrEventListenerObject
        public get onViewChanged(): EventListenerOrEventListenerObject {
            return this.onViewChanged;
        }
        public set onViewChanged(eventHandler: EventListenerOrEventListenerObject) {
            if (this.onViewChanged) {
                this.removeEventListener("viewChanged", this._onViewChanged);
                this._onViewChanged = null;
            }

            this._onViewChanged = eventHandler;
            this.addEventListener("viewChanged", this._onViewChanged);
        }



        constructor() {
        }


        public processAll() {


            if (!this.processed) {

                this._element = <HTMLElement>document.querySelector('.app');


                // Add listeners for media query changes 
                var xsmallListener = window.matchMedia(this.xsmall);
                xsmallListener.addListener((mediaQueryList: MediaQueryList) => {

                    if (!mediaQueryList.matches)
                        return;

                    this.onViewXSmallInternal();
                });

                var smallListener = window.matchMedia(this.small);
                smallListener.addListener((mediaQueryList: MediaQueryList) => {

                    if (!mediaQueryList.matches)
                        return;

                    this.onViewSmallInternal();
                });

                var mediumListener = window.matchMedia(this.medium);
                mediumListener.addListener((mediaQueryList: MediaQueryList) => {

                    if (!mediaQueryList.matches)
                        return;

                    this.onViewMediumInternal();
                });

                var largeListener = window.matchMedia(this.large);
                largeListener.addListener((mediaQueryList: MediaQueryList) => {

                    if (!mediaQueryList.matches)
                        return;

                    this.onViewLargeInternal();
                });


                this.processed = true;
            }



            // calculates adaptive-fills
            var elements = document.getElementsByClassName("adaptive-fill");
            for (var index = 0; index < elements.length; index++) {
                (<HTMLElement>elements[index]).style.height = 'calc(100vh - ' + (<HTMLElement>elements[index]).offsetTop.toString() + 'px)'
            }

            var elements = document.getElementsByClassName("adaptive-fill-relative");
            for (var index = 0; index < elements.length; index++) {
                var poffset = (<HTMLElement>elements[index]).parentElement.offsetTop;
                var eoffset = (<HTMLElement>elements[index]).offsetTop;
                var offset = eoffset - poffset;

                (<HTMLElement>elements[index]).style.height = 'calc(100% - ' + offset.toString() + 'px)'
            }


            // update state
            if (window.matchMedia(this.xsmall).matches)
                this.onViewXSmallInternal();

            if (window.matchMedia(this.small).matches)
                this.onViewSmallInternal();

            if (window.matchMedia(this.medium).matches)
                this.onViewMediumInternal();

            if (window.matchMedia(this.large).matches)
                this.onViewLargeInternal();

        }



        private dispatchEvent = (name: string, id: string, data: any) => {
            var event;
            try {
                event = new CustomEvent(name, {
                    bubbles: true,
                    cancelable: true,
                    detail: {
                        id: id,
                        data: data
                    }
                });
            } catch (e) {
                event = document.createEvent('CustomEvent');
                event.initCustomEvent(name, true, true, { id: id, data: data });
            }

            this._element.dispatchEvent(event);
        }



        private onViewXSmallInternal = () => {
            this._element.className = 'app x-small';
            this.dispatchEvent("viewXSmall", "", {});
            this.dispatchEvent("viewChanged", "", {size: "x-small"});
        };

        private onViewSmallInternal = () => {
            this._element.className = 'app small';
            this.dispatchEvent("viewSmall", "", {});
            this.dispatchEvent("viewChanged", "", { size: "small" });
        };

        private onViewMediumInternal = () => {
            this._element.className = 'app medium';
            this.dispatchEvent("viewMedium", "", {});
            this.dispatchEvent("viewChanged", "", { size: "medium" });
        };

        private onViewLargeInternal = () => {
            this._element.className = 'app large';
            this.dispatchEvent("viewLarge", "", {});
            this.dispatchEvent("viewChanged", "", { size: "large" });
        };



        public addEventListener = function (type: string, listener: EventListenerOrEventListenerObject, useCapture?: boolean) {
            this._element.addEventListener(type, listener, useCapture);
        };

        public removeEventListener = function (type: string, listener: EventListenerOrEventListenerObject, useCapture?: boolean) {
            this._element.removeEventListener(type, listener, useCapture);
        };

    }

    export var Adaptive = new _Adaptive();
}

